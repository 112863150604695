import setFormListeners from './main-scripts/form-events.js';
import setNumberInputs from './main-scripts/input-number.js';
import './main-scripts/megamenu.js';
import initPopupsHeader from './main-scripts/popups-header.js';
import initPrintButtons from './main-scripts/print-button.js';
import { handleAddToCart } from './modules/_add-to-cart.js';
import initCartItemRemove from './modules/_cart-item-remove.js';

var documentReady = function () {
    initPrintButtons();
    initPopupsHeader();
    setNumberInputs();
    handleAddToCart();
    initCartItemRemove();
    setFormListeners();
};

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    documentReady();
} else {
    document.addEventListener('DOMContentLoaded', documentReady);
}
