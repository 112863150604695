import { handleToast } from './_handle-toast';

export const handleAddToCart = () => {
    const cartButton = document.querySelectorAll(
        '.js-single-add-to-cart:not(.-configurator)'
    );
    const popupMiniCart = document.querySelector('[data-popup-mini-cart]');
    const popupMiniCartItems = popupMiniCart
        ? popupMiniCart.querySelector('[data-popup-mini-cart-items]')
        : null;

    cartButton.forEach(item => {
        item.addEventListener('click', async e => {
            e.preventDefault();
            const { currentTarget } = e;

            const button = currentTarget;
            const form = button.closest('form.cart-button');
            const input = form.querySelector('input[name="quantity"]');
            const qty = input ? parseInt(input.value) : 1;

            await addToCart({
                button: button,
                productId: button.dataset.product_id,
                qty: qty,
                additionalData: {},
                formData: null,
                noTippy: true,
            });

            const paramsMiniCart = new URLSearchParams({
                action: 'get_mini_cart_html',
            });

            const requestMiniCart = await fetch(ajax.url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: paramsMiniCart.toString(),
            });

            const res = await requestMiniCart.json();
            const { html } = res;

            popupMiniCartItems.innerHTML = html;

            if (popupMiniCart) popupMiniCart.classList.add('-active');
        });
    });
};

export const addToCart = async function addToCart({
    action = 'mda_add_to_cart',
    button,
    productId,
    qty,
    additionalData = {},
    formData = null,
    withoutPrinting = true,
    noTippy = false,
}) {
    const params = new URLSearchParams({
        action: action,
        product_id: productId,
        qty: qty,
        ...additionalData,
        without_printing: withoutPrinting,
    });

    const cartQtyEl = document.querySelector('[data-cart-quantity]');

    /*if (cartQtyEl)
        cartQtyEl.parentElement
            .querySelector('.loader')
            .classList.add('-active');*/

    let data = '';

    try {
        handleAjaxBeforeSend(button);
        let response = null;

        if (!formData) {
            response = await fetch(ajax.url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: params.toString(),
            });
        } else {
            response = await fetch(ajax.url, {
                method: 'POST',
                body: formData,
            });
        }

        data = await response.json();
        const { cart_qty, status, message } = data;

        if (cart_qty) cartQtyEl.textContent = cart_qty;
        if (status && message && !noTippy) handleToast(status, message);
    } catch (error) {
        console.error('Error:', error);
    } finally {
        //if (cartQtyEl) cartQtyEl.parentElement.querySelector('.loader').classList.remove('-active');
        handleAjaxSuccess(data, button);
    }
};

function handleAjaxBeforeSend(button) {
    button.classList.add('-is-loading', '-disabled-add');
}

function handleAjaxSuccess(response, button) {
    button.classList.remove('-is-loading', '-disabled-add');

    if (response.status === 'success') {
        button.classList.add('-is-done');

        setTimeout(() => {
            button.classList.remove('-is-done');
        }, 3000);
    }
}

export const calculatePrice = async function (
    isLabelling = true,
    formEl = null,
    productID = -1,
    qty = 1,
    priceEl = null,
    summaryEl = null,
    pagePriceAmountEl = null
) {
    const formData = new FormData();
    const labellingPos = [];
    const labellingColor = [];
    const labellingTechs = [];

    const currentForm = new FormData(formEl);

    if (isLabelling) {
        for (let [key, val] of currentForm.entries()) {
            if (key.includes('labelling_position')) labellingPos.push(val);
            if (key.includes('labelling_tech')) labellingTechs.push(val);
            if (key.includes('labelling_color')) labellingColor.push(val);
        }

        if (labellingPos && labellingPos.length > 0)
            formData.append('labelling_position', JSON.stringify(labellingPos));
        if (labellingColor && labellingColor.length > 0)
            formData.append('labelling_color', JSON.stringify(labellingColor));
        if (labellingTechs && labellingTechs.length > 0)
            formData.append('labelling_tech', JSON.stringify(labellingTechs));
    }

    formData.append('product_id', productID);
    formData.append('quantity', qty);

    if (!qty || qty === '') return;

    const request = await fetch(
        `${home_url}/wp-json/configurator/v1/price/get`,
        {
            method: 'POST',
            body: formData,
        }
    );

    const res = await request.json();
    const { data, summary, price_html, price_format } = res;
    const { price } = data;

    if (priceEl) priceEl.innerHTML = price_format;
    if (summaryEl) summaryEl.innerHTML = summary;
    if (pagePriceAmountEl) pagePriceAmountEl.innerHTML = price_html;

    return price.toFixed(2);
};
