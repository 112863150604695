import { loadPopup } from '../helper/_popup';

class PopupsHeader {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.popupButtonEl = this.root.querySelectorAll('[data-popup-data]');
        this.loadPopup = loadPopup;

        return true;
    }

    init() {
        this.initPopupButtons();
    }

    initPopupButtons() {
        if (!this.popupButtonEl) return;

        this.onPopupButton = this.onPopupButtonClick.bind(this);
        this.popupButtonEl.forEach(item => {
            item.addEventListener('click', this.onPopupButton);
        });
    }

    async onPopupButtonClick(event) {
        const { currentTarget } = event;
        const data = JSON.parse(currentTarget.getAttribute('data-popup-data'));
        const formData = new FormData();
        if (data) formData.append('popup-data', JSON.stringify(data));

        let popupInstance = null;

        await this.loadPopup(currentTarget, popupInstance, formData);
    }
}

export default function initPopupsHeader() {
    const header = document.querySelector('.header');
    if (!header) return;

    new PopupsHeader(header);
}
