import { handleToast } from '../modules/_handle-toast';

export default function setFormListeners() {
    window.addEventListener('mda-contact-form-sent', event => {
        const { detail } = event;
        const { status, message } = detail;

        handleToast(status, message);
    });
}
