export default class Print {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;

        return true;
    }

    init() {
        this.setButton();
    }

    setButton() {
        this.onClick =  this.onPrintClick.bind(this);
        this.root.addEventListener('click', this.onClick);
    }

    onPrintClick() {
        window.print();
    }
}