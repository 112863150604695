import Popup from '../modules/_popup';

export default class CartItemRemovePopup extends Popup {
    constructor(el) {
        super(el);
        this.removeButtonEl = this.root.querySelector(
            '[data-popup-remove-confirm]'
        );

        this.setOnRemove();
    }

    setOnRemove() {
        if (!this.removeButtonEl) return;
        this.removeButtonEl.addEventListener('click', this.onRemove.bind(this));
    }

    setCartItemKey(key) {
        this.cartItemKey = key;
    }

    async onRemove() {
        if (!this.cartItemKey) return;

        await this.removeFromCart();

        this.close();
        window.location.reload();
    }

    async removeFromCart() {
        const paramsRemoveFromCart = new URLSearchParams({
            action: 'remove_from_cart',
            cart_item_key: this.cartItemKey,
        });

        const requestRemoveFromCart = await fetch(ajax.url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: paramsRemoveFromCart.toString(),
        });

        const res = await requestRemoveFromCart.json();
        const { html } = res;
    }
}
