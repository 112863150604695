import Toastify from 'toastify-js';
export const handleToast = (status, text, duration = 5000) => {
    Toastify({
        text: text,
        className: status,
        stopOnFocus: true,
        duration: duration,
        close: false,
        gravity: 'bottom',
        position: 'left',
    }).showToast();
};
