import {
    getElementOrParentBySelector,
    removeClassFromAll,
} from '../helper/dom-helpers';

const megamenuSelector = '[data-megamenu]';
const megamenuTriggerSelector = '[data-megamenu-trigger-main]';
const megamenuTriggerItemSelector = '[data-megamenu-trigger-item]';
const megamenuSubmenuItem = '[data-megamenu-submenu]';
const megamenuTriggerItemTarget = 'data-megamenu-target';
const megamenuTriggerItemSubmenu = 'data-megamenu-submenu-id';
const activeClass = 'is-opened';
const megamenuCloseAllSelector = '[data-megamenu-close]';
const megamenuCloseCurrentSelector = '[data-megamenu-close-current]';
const megamenuCloseTargetSelectorName = 'data-megamenu-close-id';
const bodyActiveClass = 'mega-menu-is-opened';

document.body.style.setProperty(
    '--full-height',
    `${document.body.scrollHeight}px`
);

function addClassIfNotExists(element, className) {
    if (!element.classList.contains(className)) {
        element.classList.add(className);
    }
}

class megaMenu {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.mainTrigger = document.querySelectorAll(megamenuTriggerSelector);
        this.itemTrigger = document.querySelectorAll(
            megamenuTriggerItemSelector
        );
        this.closeAll = document.querySelectorAll(megamenuCloseAllSelector);
        this.closeCurrent = document.querySelectorAll(
            megamenuCloseCurrentSelector
        );

        return true;
    }

    init() {
        this.initClickOutside();
        this.initMainTrigger();
        this.initItemTrigger();
        this.initCloseAll();
        this.initCloseCurrent();
    }

    initMainTrigger() {
        if (!this.mainTrigger) return;

        this.mainTrigger.forEach(item => {
            item.addEventListener('click', event => {
                event.preventDefault();
                if (item.classList.contains('-active')) {
                    item.classList.remove('-active');
                } else {
                    item.classList.add('-active');
                }
                this.toggleMain();
            });
        });
    }

    initItemTrigger() {
        if (!this.itemTrigger) return;

        this.itemTrigger.forEach(item => {
            item.addEventListener('click', function (event) {
                const { currentTarget } = event;
                let menuTarget = currentTarget.getAttribute(
                    megamenuTriggerItemTarget
                );
                let menu = document.querySelector(
                    '[' + megamenuTriggerItemSubmenu + '="' + menuTarget + '"]'
                );
                if (menu) {
                    event.preventDefault();
                    removeClassFromAll(megamenuSubmenuItem, activeClass);
                    menu.classList.add(activeClass);
                }
            });
        });
    }

    initClickOutside() {
        let elem = this.root;
        document.addEventListener('click', event => {
            let check = getElementOrParentBySelector(event, megamenuSelector);
            let checkTrigger = getElementOrParentBySelector(
                event,
                megamenuTriggerSelector
            );
            if (
                !check &&
                !checkTrigger &&
                elem.classList.contains(activeClass)
            ) {
                elem.classList.remove(activeClass);
                document.body.classList.remove(bodyActiveClass);
                this.removeFixedFromHeader();
                this.mainTrigger.forEach(trigger => {
                    trigger.classList.remove('-active');
                });
            }
        });
    }

    initCloseAll() {
        if (!this.closeAll) return;

        this.closeAll.forEach(item => {
            item.addEventListener('click', event => {
                event.preventDefault();
                removeClassFromAll(megamenuSubmenuItem, activeClass);
                this.root.classList.remove(activeClass);
                document.body.classList.remove(bodyActiveClass);
                this.removeFixedFromHeader();
            });
        });
    }

    initCloseCurrent() {
        if (!this.closeCurrent) return;

        this.closeCurrent.forEach(item => {
            item.addEventListener('click', function (event) {
                const { currentTarget } = event;
                let menuTarget = currentTarget.getAttribute(
                    megamenuCloseTargetSelectorName
                );
                let menu = document.querySelector(
                    '[' + megamenuTriggerItemSubmenu + '="' + menuTarget + '"]'
                );
                if (menu) {
                    event.preventDefault();
                    menu.classList.remove(activeClass);
                    if (!document.querySelector('.' + activeClass)) {
                        document.body.classList.remove(bodyActiveClass);
                        this.removeFixedFromHeader();
                    }
                }
            });
        });
    }

    toggleMain() {
        const header = document.getElementById('header');

        if (this.root.classList.contains(activeClass)) {
            this.root.classList.remove(activeClass);
            document.body.classList.remove(bodyActiveClass);
            this.removeFixedFromHeader();
        } else {
            this.root.classList.add(activeClass);
            document.body.classList.add(bodyActiveClass);
            if (header) {
                addClassIfNotExists(header, '-is-fixed');
            }
        }
    }

    removeFixedFromHeader() {
        const header = document.getElementById('header');
        if (header) {
            const scrollY = window.scrollY || window.pageYOffset;

            if (scrollY === 0 && header.classList.contains('-is-fixed')) {
                header.classList.remove('-is-fixed');
            }
        }
    }
}

function initMegaMenu() {
    const sections = document.querySelectorAll(megamenuSelector);
    if (!sections) return;

    sections.forEach(item => new megaMenu(item));
}

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    initMegaMenu();
} else {
    document.addEventListener('DOMContentLoaded', initMegaMenu);
}
