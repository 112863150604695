export function removeClassFromAll(querySelector, removeClass) {
    var elems = document.querySelectorAll(querySelector);
    [].forEach.call(elems, function (el) {
        el.classList.remove(removeClass);
    });
}

export function getElementOrParentBySelector(event, querySelector) {
    let domElem = false;
    if (event.target.matches(querySelector)) {
        domElem = event.target.matches(querySelector);
    } else if (event.target.closest(querySelector)) {
        domElem = event.target.closest(querySelector);
    }
    return domElem;
}
