export default function setNumberInputs() {
    const numberInputs = document.querySelectorAll('input[type="number"]');
    numberInputs.forEach(item => {
        item.addEventListener('keyup', event => {
            const { currentTarget } = event;
            const { value, min, max } = currentTarget;

            if (value != '') {
                if (parseInt(value) < parseInt(min)) {
                    currentTarget.value = min;
                }
                if (parseInt(value) > parseInt(max)) {
                    currentTarget.value = max;
                }
            }
        });
    });
}
