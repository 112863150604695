import CartItemRemovePopup from '../modules/_cart-item-remove-popup';

class CartItemRemove {
    constructor(el, popupHandler) {
        if (!this.set(el, popupHandler)) return;
        this.init();
    }

    set(el, popupHandler) {
        if (!el || !popupHandler) return false;

        this.root = el;
        this.popupHandler = popupHandler;

        return true;
    }

    init() {
        if (this.popupHandler) this.setRemove();
    }

    setRemove() {
        this.root.addEventListener('click', this.onRemove.bind(this));
    }

    onRemove(event) {
        event.preventDefault();

        const { currentTarget } = event;
        const cartItemKey = currentTarget.getAttribute('data-product-key');

        this.popupHandler.setCartItemKey(cartItemKey);
        this.popupHandler.open();
    }
}

export default function initCartItemRemove() {
    const sections = document.querySelectorAll('.cart-table__remove');
    if (!sections) return;

    const popupEl = document.querySelector('[data-popup-remove]');
    const popupHandler = popupEl ? new CartItemRemovePopup(popupEl) : null;

    sections.forEach(item => new CartItemRemove(item, popupHandler));
}
